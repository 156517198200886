import React from "react";
import Routes from "./Core/Routes";
import { history } from "../src/Core/Store"
import { setBaseUrl } from './Services/HttpService';
import { ToastProvider } from 'react-toast-notifications';
import TagManager from 'react-gtm-module'
import { IntercomProvider, useIntercom } from 'react-use-intercom';
import {httpInterceptor} from "./Services/HttpService"
import { hotjar } from 'react-hotjar';
const INTERCOM_APP_ID = 'g4kiztyb';
const hjid = '2524808';
const hjsv='6';

function App() {

  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM,
    dataLayerName: 'dataLayer'
  }

  //Use Effects
  React.useEffect(() => {
    setBaseUrl(process.env.REACT_APP_BASE_URL);
    httpInterceptor();
    if(process.env.REACT_APP_ENV === "production"){
      console.log("production is running");
      hotjar.initialize(hjid, hjsv);
      TagManager.initialize(tagManagerArgs);
    }
  }, []);

  //Rendering
  return (
    <div className="App">
      <IntercomProvider appId={INTERCOM_APP_ID}>
        <ToastProvider placement="top-right" autoDismiss="true" autoDismissTimeout="1200">
          <Routes history={history} />
        </ToastProvider>
      </IntercomProvider>
    </div>
  );
}

export default App;
